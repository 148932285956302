import { __awaiter, __generator } from "tslib";
export var pWhile = function (condition, action) { return __awaiter(void 0, void 0, void 0, function () {
    var loop;
    return __generator(this, function (_a) {
        loop = function (actionResult) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!condition(actionResult)) return [3 /*break*/, 2];
                        _a = loop;
                        return [4 /*yield*/, action()];
                    case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        return [2 /*return*/, loop(undefined)];
    });
}); };
